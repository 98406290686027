/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CONCILIATE_MOVEMENTS = gql`
  mutation conciliate(
    $creditMovements: [ConciliationInput]!
    $debitMovements: [ConciliationInput]!
    $description: String!
    $conciliationMovementTypeId: Int!
    ) {
      conciliate(
        creditMovements: $creditMovements
        debitMovements: $debitMovements
        description: $description
        conciliationMovementTypeId: $conciliationMovementTypeId
    ) {
      success
    }
  }
`;
