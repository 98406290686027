/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { MoneyFields } from '@fingo/lib/graphql/moneyFields';

export const COMPANY_BALANCES = gql`
  query CompanyBalances(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $countryId: Int
    $validSiiCredentials: Boolean
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      countryId: $countryId
      validSiiCredentials: $validSiiCredentials
    ) {
      totalCount
      edges {
        node {
          id
          name
          displayNationalIdentifier
          company {
            id
            currentSurplusBalanceValue {
              ...MoneyFields
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
`;
