import { gql } from '@apollo/client';
import { MoneyFields } from '@fingo/lib/graphql/moneyFields';

export const CONCILIATION_MOVEMENTS = gql`
  query conciliationMovements(
    $first: Int
    $offset: Int
    $orderBy: String
    $globalFilter: String
    $debit_Gt: String
    $credit_Gt: String
    $contentType_Id: ID
    $conciliationMovementAccount_Id_In: [String]
    $conciliated: Boolean
    $id_In: [String]
    $movementDate_Isnull: Boolean
    $filterInvoices: Upload
    $countryId: String
    $currency: String
  ) {
    conciliationMovements(
      first: $first
      offset: $offset
      orderBy: $orderBy
      globalFilter: $globalFilter
      debit_Gt: $debit_Gt
      credit_Gt: $credit_Gt
      contentType_Id: $contentType_Id
      conciliationMovementAccount_Id_In: $conciliationMovementAccount_Id_In
      conciliated: $conciliated
      id_In: $id_In
      movementDate_Isnull: $movementDate_Isnull
      filterInvoices: $filterInvoices
      countryId: $countryId
      currency: $currency
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          updatedAt
          description
          debit {
            ...MoneyFields
          }
          credit {
            ...MoneyFields
          }
          movementDate
          totalAmount @client
          conciliationMovementAccount {
            id
            name
            code
          }
          masterEntity {
            id
            rut
            name
          }
        }
      }
    }
  }
  ${MoneyFields}
`;

export const MOVEMENT_ACCOUNTS = gql`
  query conciliationMovementAccounts {
    conciliationMovementAccounts {
      id
      code
      name
      active
      passive
      updatedAt
    }
  }
`;

export const CONCILIATED_MOVEMENT_TYPE = gql`
  query conciliatedMovementType {
    conciliatedMovementType {
      id
      name
      code
      label @client
    }
  }
`;
