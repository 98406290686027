/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { MoneyFields } from '@fingo/lib/graphql/moneyFields';

export const CHANGE_COMPANY_SURPLUS = gql`
  mutation ChangeCompanySurplus($companyId: String!, $amount: Float!) {
    changeCompanySurplus(companyId: $companyId, amount: $amount) {
      masterEntity {
        id
        company {
          id
          currentSurplusBalanceValue {
            ...MoneyFields
          }
        }
      }
    }
  }
  ${MoneyFields}
`;
