/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_NON_OPERATIVE_MOVEMENT = gql`
  mutation createNonOperativeMovement(
    $movement: NewMovementInput
    $countryId: String!
  ) {
    createNonOperativeMovement(
      movement: $movement
      countryId: $countryId
    ) {
      movement {
        id
      }
      relatedConciliationMovement {
        id
      }
    }
  }
`;
