/* eslint-disable import/prefer-default-export */
import { formatMoney } from '@fingo/lib/helpers';

export const COLUMNS = [
  {
    field: 'name',
    headerName: 'Cliente',
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'displayNationalIdentifier',
    headerName: 'RUT',
    disableColumnMenu: true,
  },
  {
    field: 'balance',
    headerName: 'Balance de excedentes',
    valueGetter: ({ row }) => row.company?.currentSurplusBalanceValue,
    valueFormatter: ({ value }) => `${formatMoney(value)}`,
    sortable: false,
    disableColumnMenu: true,
    width: 200,
  },
];
