import CancelRounded from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpRounded from '@mui/icons-material/HelpRounded';
import WarningRounded from '@mui/icons-material/WarningRounded';
import React, { useCallback, useMemo } from 'react';
import { selectedRiskComponentVar } from '../features/risk/graphql/reactive-variables';

const useAlertModelsResults = (invoice) => {
  const { riskEvaluationModel } = invoice;

  const iconStatuses = useMemo(() => ({
    success: <CheckCircleIcon color="success" />,
    failure: <CancelRounded color="error" />,
    warning: <WarningRounded color="warning" />,
    unknown: <HelpRounded color="warning" />,
  }), []);

  const treeStatus = useMemo(() => (
    (riskEvaluationModel?.treeClientResult
      && riskEvaluationModel?.treeDebtorResult
      && riskEvaluationModel?.treeClientDebtorResult)
      ? 'success'
      : 'failure'
  ), [riskEvaluationModel]);

  const rasStatus = useMemo(() => (
    ((riskEvaluationModel.rasClientResult * 0.4
      + riskEvaluationModel.rasDebtorResult * 0.4
      + riskEvaluationModel.rasClientDebtorResult * 0.2).toFixed(2))
      ? 'success'
      : 'failure'
  ), [riskEvaluationModel]);

  const handleTreeClick = useCallback(() => selectedRiskComponentVar('tree'), []);
  const handleRasClick = useCallback(() => selectedRiskComponentVar('ras'), []);

  const alertsConfig = useMemo(() => [
    {
      id: 'tree',
      title: 'Arbol',
      icon: iconStatuses[treeStatus],
      onClick: handleTreeClick,
    },
    {
      id: 'ras',
      title: 'RAS',
      icon: iconStatuses[rasStatus],
      onClick: handleRasClick,
    },
  ], [riskEvaluationModel]);

  return { alertsConfig };
};

export default useAlertModelsResults;
