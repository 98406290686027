import { formatMoney, addMoneyWithCurrency } from '@fingo/lib/helpers';
import { formatText } from '@fingo/lib/helpers/text-parser';
import theme from '@fingo/lib/theme';
import moment from 'moment';

export const invoiceAmounts = ({ amountWithIva, paymentDiscounts, offer }) => [
  { title: 'Monto total', value: `$${formatMoney(amountWithIva)}` },
  { title: 'Retención', value: `$${formatMoney(paymentDiscounts.retentionAssociated)}` },
  { title: 'Dif. precio', value: `$${formatMoney(paymentDiscounts.interestAsociated)}` },
  { title: 'Comisión', value: `$${formatMoney(paymentDiscounts.commissionAsociated)}` },
  {
    title: 'Dcto. Ordering',
    value: `$${formatMoney(paymentDiscounts.orderingAmountAssociated)}`,
  },
  {
    title: 'Dcto. reint/reem',
    value: `$${formatMoney(
      addMoneyWithCurrency(
        paymentDiscounts.reintegrationAmountAssociated,
        paymentDiscounts.replacementAmountAssociated,
      ),
    )}`,
  },
  {
    title: 'Dcto. excedentes',
    value: `$${formatMoney(paymentDiscounts.negativeSurplusAsociated)}`,
  },
  { title: 'Tasa mora', value: `${offer.defaultRate}%` },
];

const restrictionWithNoFiles = [
  'NEGATIVE_SURPLUS_PAYMENT_CONFIRMATION',
  'COLLECTION_DELINQUENT_INVOICE',
  'REGULARIZE_REFUND',
  'REGULARIZE_REPLACEMENT',
  'GET_PAYMENT_DATE_FOR_OVERDUE_INVOICES',
  'OTHER',
  'PREPAY_OVERDUE_INVOICE',
  'COLLECTION_DELINQUENT_PURCHASEORDER',
  'REGULARIZE_REFUND_OR_REPLACEMENT',
];

export const restrictionRequiresFile = (value) => !restrictionWithNoFiles.includes(value);

export const complianceStatusMessage = (compliance, hasMultipleOperations, nonCompliant) => {
  const { requirementsClean } = compliance || {};
  const information = nonCompliant
    ? nonCompliant.relatedPerson?.compliance?.information
    : compliance.information;
  if (!hasMultipleOperations) return 'Listo para girar, primera operación';
  if (compliance && requirementsClean) return information;
  if (compliance && !requirementsClean) return `Normativas no cumplidas: ${information}`;
  return 'Su solicitud está siendo procesada';
};

export const buildComplianceOperationDetailUrl = (complianceId) => `https://app.regcheq.com/home/records/operationDetail/${complianceId}`;

export const riskRestrictionRequirementsIconColor = (restrictions) => {
  if (!restrictions.length) { return theme.palette.success.main; }

  const pendingResolution = restrictions.some(({ status }) => status === 'CREATED');
  if (pendingResolution) { return theme.palette.error.secondary; }

  return theme.palette.warning.main;
};

export const riskRestrictionToDocumentType = (restriction) => {
  switch (restriction) {
    case 'CONTRACT_SIGNING':
      return 'contract';
    case 'NON_PAYMENT_CLARIFICATION':
      return 'non_payment_clarification';
    case 'TGR_AGREEMENT':
      return 'tgr_agreement_proof';
    case 'PAY_PAYMENTPLAN_INSTALLMENT':
      return 'payment_plan_installment_proof';
    default:
      return '';
  }
};

export const riskRestrictionToDocumentLabel = (restriction) => {
  switch (restriction) {
    case 'CONTRACT_SIGNING':
      return 'Contrato';
    case 'NON_PAYMENT_CLARIFICATION':
      return 'Aclaración';
    case 'TGR_AGREEMENT':
      return 'Comprobante';
    case 'PAY_PAYMENTPLAN_INSTALLMENT':
      return 'Comprobante';
    default:
      return '';
  }
};

export const findCompanyRiskRestrictionsDocument = (companyDocuments, rowRestriction) => {
  const docType = riskRestrictionToDocumentType(rowRestriction);
  const companyDocument = companyDocuments?.find((document) => document.documentType === docType);
  const documentLabel = riskRestrictionToDocumentLabel(rowRestriction);

  return {
    ...companyDocument,
    documentType: docType,
    label: documentLabel,
  };
};

export const BFStatusData = (signedDate) => ({
  FIRST_OPERATION_PENDING_BF_SENDING: {
    tooltip: 'Primera operación, pendiente envío de formulario.',
    color: theme.palette.error.secondary,
  },
  FIRST_OPERATION: {
    tooltip: 'Primera operación, formulario enviado.',
    color: theme.palette.success.main,
  },
  BF_SIGNED: {
    tooltip: formatText(`Regcheq: Formulario firmado y listo para descargar. </br>Fecha de firma: ${signedDate}`),
    color: theme.palette.success.main,
  },
  BF_SIGNED_TO_EXPIRE: {
    tooltip: formatText(`Formulario listo y firmado, próximo a vencer. </br>Fecha de firma: ${signedDate}`),
    color: theme.palette.warning.main,
  },
  BF_EXPIRED: {
    tooltip: formatText(`Formulario firmado expirado. </br>Fecha de firma: ${signedDate}`),
    color: theme.palette.error.secondary,
  },
  PENDING: {
    tooltip: 'Su solicitud está siendo procesada.',
    color: theme.palette.warning.light,
  },
  REJECTED: {
    tooltip: 'Formulario requiere firma o no cumple con normativa.',
    color: theme.palette.error.secondary,
  },
  SENT: {
    tooltip: 'Formulario enviado. Pendiente de Firma',
    color: theme.palette.warning.light,
  },
});

export const calculateBFStatus = (BFStatus, hasMultipleOperations, compliance) => {
  // Maybe this status should be calculated in server-side.
  const { signedDate } = compliance;

  // 1. If its signed, check if it has expired
  if (BFStatus === 'FINISHED') {
    const signedDateFormatted = moment(signedDate);
    const today = moment().startOf('day');
    const daysDiff = today.diff(signedDateFormatted, 'days');
    if (daysDiff > 365) {
      return BFStatusData(signedDate).BF_EXPIRED;
    }
    const daysToExpire = 365 - daysDiff;
    if (daysToExpire < 30) {
      return BFStatusData(signedDate).BF_SIGNED_TO_EXPIRE;
    }
    return BFStatusData(signedDate).BF_SIGNED;
  }

  // 2. If its not signed, see if its first operation
  if (!hasMultipleOperations) {
    if (BFStatus === 'REJECTED' || BFStatus === null) {
      return BFStatusData(signedDate).FIRST_OPERATION_PENDING_BF_SENDING;
    }
    return BFStatusData(signedDate).FIRST_OPERATION;
  }
  if (BFStatus === 'REJECTED') {
    return BFStatusData(signedDate).REJECTED;
  }
  if (BFStatus === 'PROCESSING') {
    return BFStatusData(signedDate).SENT;
  }
  return BFStatusData(signedDate).PENDING;
};
