import React from 'react';
import {
  hasShowSubRoutes,
  userHasRoutePermission,
} from '@fingo/lib/helpers/routes';
import { FingoPaper } from '@fingo/lib/components/layout';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Conciliation, TodaysConciliations } from '../components/conciliation';
import Movements from '../components/movements/Movements';
import PurchaseOrderTransfersList from '../components/transfers/PurchaseOrderTransfersList';
import InvoiceLoanTransfersList from '../components/transfers/InvoiceLoanTransfersList';
import { ConciliationPaper, Transfers } from '../components';
import TransfersList from '../components/transfers/TransfersList';
import ConfirmingTransfersList from '../components/ConfirmingTransfersList';
import CompanyBalances from '../components/CompanyBalances';

export const conciliationTabs = (user) => [
  {
    id: 'conciliation-tab',
    label: 'Conciliación',
    path: 'all',
    component: <Conciliation />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'banking.view_conciliationmovement'),
  },
  {
    id: 'today-conciliations-tab',
    label: 'Conciliaciones de hoy',
    path: 'todays',
    component: <TodaysConciliations />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'banking.view_conciliationmovement'),
  },
  {
    id: 'movements',
    label: 'Movimientos no operativos creados',
    path: 'movements',
    component: <Movements />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'banking.view_conciliationmovement'),
  },
];

export const nonOperativeMovementTabs = (user) => [
  {
    id: 'movements',
    label: 'Movimientos',
    path: 'movements',
    component: <Movements />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'banking.view_nonoperativemovement'),
  },
];

export const transfersTabs = (user) => [
  {
    id: 'transfers-tab',
    label: 'Factoring',
    path: 'factoring',
    component: <TransfersList />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(
      user,
      'money_transfer.view_invoicemoneytransfer',
    ),
  },
  {
    id: 'ordering-tab',
    label: 'Ordering',
    path: 'ordering',
    component: <PurchaseOrderTransfersList />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(
      user,
      'money_transfer.view_invoicemoneytransfer',
    ),
  },
  {
    id: 'loan-managers-tab',
    label: 'Devolución Fondos',
    path: 'roster-transfers',
    component: <InvoiceLoanTransfersList />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(
      user,
      'money_transfer.view_invoiceloanmoneytransfer',
    ),
  },
  {
    id: 'confirming-transfer-tab',
    label: 'Confirming',
    path: 'confirming-transfer',
    component: <ConfirmingTransfersList />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(
      user,
      'money_transfer.view_invoicemoneytransfer',
    ),
  },
];

export const surplusTabs = (user) => [
  {
    id: 'surplus',
    label: 'Excedentes',
    path: 'surplus',
    component: <CompanyBalances />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'surplus_v2.view_companybalance'),
  },
];

export const treasuryRoutes = (user) => [
  {
    id: 'conciliation',
    label: 'Conciliación',
    path: 'conciliation',
    icon: <DescriptionOutlinedIcon />,
    component: <ConciliationPaper />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, conciliationTabs(user)),
  },
  {
    id: 'transfer',
    label: 'Giros',
    path: 'transfers',
    icon: <DescriptionOutlinedIcon />,
    component: <Transfers />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, transfersTabs(user)),
  },
  {
    id: 'surplus',
    label: 'Excedentes',
    path: 'surplus',
    icon: <DescriptionOutlinedIcon />,
    component: <FingoPaper tabs={surplusTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, surplusTabs(user)),
  },
];
