import { gql } from '@apollo/client';
import { CompanyDocumentsFields, CompanyDebtsSummary, ComplianceFields, CompanyRestrictions, RiskBlacklistFields } from '@fingo/lib/graphql/customers/fragment';
import { UserIdentification } from '@fingo/lib/graphql/user/fragments';
import { MoneyFields } from '@fingo/lib/graphql/moneyFields';
import { InvoiceMoneyTransferFields, MasterEntityTypeConnectionFields } from '@fingo/lib/graphql';

export const TRANSFERS_MASTER_ENTITIES = gql`
  query transfersMasterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $countryId: Int
    $transferRestrictionsReady: Boolean
  ){
    getMasterEntities(
    first: $first
    offset: $offset
    globalFilter: $globalFilter
    orderBy: $orderBy
    countryId: $countryId
    inFactoringTransfer: true
    transferRestrictionsReady: $transferRestrictionsReady
    ){
      ...MasterEntityTypeConnectionFields
      edges {
        node {
          id
          rut
          name
          displayNationalIdentifier
          riskBlacklist {
            ...RiskBlacklistFields
          }
          compliance {
            ...ComplianceFields
          }
          source {
            id
            name
          }
          ...CompanyDebtsSummary
          company {
            id
            name
            hasLendingAcceptedterms @client
            currentUserAcceptedTerms {
              id
              product
            }
            hasDigitalCertificate
            hasFrameworkContract
            hasMultipleOperations
            documents {
              ... CompanyDocumentsFields
            }
            executiveAssigned {
              ... UserIdentification
            }
            legalRepresentative {
              id
              relatedPerson {
                id
                name
                compliance {
                  id
                  requirementsClean
                  information
                }
              }
            }
            companyevaluationrestrictionsSet {
              ...CompanyRestrictions
            }
            invoicesInPendingTransfer{
              id
              amountWithIva{
                ...MoneyFields
              }
              paymentDiscounts{
                paymentAmountAfterNegativeSurplus {
                  ...MoneyFields
                }
              }
              ... InvoiceMoneyTransferFields
            }
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${MasterEntityTypeConnectionFields}
  ${CompanyDocumentsFields}
  ${UserIdentification}
  ${MoneyFields}
  ${InvoiceMoneyTransferFields}
  ${ComplianceFields}
  ${CompanyRestrictions}
  ${RiskBlacklistFields}
`;

export const COUNT_TRANSFERS = gql`
  query CountTransfer(
    $countryId: Int
  ){
    factoringTransferMasterEntities: getMasterEntities(
      inFactoringTransfer: true
      countryId: $countryId
    ){
      ...MasterEntityTypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
    transferPurchaseOrders: purchaseOrders(
      availableForTransfer: true,
    ){
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
        }
      }
    }
    invoiceLoansMoneyTransfers {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
  ${MasterEntityTypeConnectionFields}
`;
